.card-1 {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__frame {
    @include frame("rect", 56.25%);
    @include frame("zoom");
    border-radius: 10px 10px 0 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 12px 20px 30px;
    // background-color: $lightbrown;
    background-color: $secondary;
    border-radius: 0 0 10px 10px;
  }

  &__title {
    font-size: 18px;
    text-align: center;
    color: $primary;
    position: relative;
    padding-bottom: 12px;
    margin-bottom: 10px;

    &::after {
      content: "";
      display: block;
      width: 98px;
      height: 6px;
      background: url("../images/menu-underline.png") center/contain no-repeat;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__desc {
    @include max-line(3);
    text-align: center;
    color: $white;
  }
}

.card-2 {
  &__frame {
    @include frame("rect", 66.66%);
    @include frame("zoom");
    margin-bottom: 10px;
  }

  &__title {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  &__time {
    color: #999;
  }
}

.card-3 {
  position: relative;

  &__frame {
    @include frame("rect", 66.66%);
    @include frame("zoom");
  }

  &__title {
    width: 100%;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: rgba($secondary, 0.6);
    margin-bottom: 0;

    a {
      display: block;
      padding: 12px 16px;
      color: $white;

      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
  }
}

.card-4 {
  &__frame {
    @include frame("rect", 66.66%);
    @include frame("zoom");
    width: 80px;
    margin-right: 12px;
  }

  &__title {
    @include max-line(3);
    font-size: 14px;
    margin-bottom: 0;
  }
}

.card-5 {
  &__frame {
    @include frame("rect", 75%);
    @include frame("zoom");
    margin-right: 12px;
    width: 111px;
  }

  &__title {
    font-size: 14px;
    margin-bottom: 8px;
  }

  &__time {
    color: #999;
  }
}

.card-6 {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__frame {
    @include frame("rect", 66.66%);
    @include frame("zoom");
    border: 8px solid $primary;
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex-grow: 1;
    background-color: $white;
  }

  &__title {
    font-size: 16px;
    margin-bottom: 8px;
  }

  &__time {
    margin-top: auto;
    margin-bottom: 8px;
    color: #666;
  }

  &__desc {
    @include max-line(3);
  }
}

.card-7 {
  display: flex;
  flex-direction: column;
  height: 100%;
  @include shadow("");
  background-color: $white;

  &__frame {
    @include frame("rect", 50%);
    @include frame("zoom");
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px;
  }

  &__title {
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    color: $primary;
    margin-bottom: 8px;
  }

  &__desc {
    @include max-line(4);
    text-align: center;
    margin-bottom: 12px;
  }

  &__btn {
    display: inline-block;
    font-size: 14px;
    line-height: 38px;
    border: 1px solid $primary;
    padding: 0 30px;
    border-radius: 20px;
    color: #333;
    transition: 0.3s;

    &:hover {
      color: $white;
      background-color: $primary;
      text-decoration: none;
    }
  }
}
