.list {
  &__item {
    &:not(:last-child) {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px dashed $border-color;
    }
  }
}

.list-2 {
  &__item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
