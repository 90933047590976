.section {
  padding: 45px 0;
  position: relative;

  &__title {
    font-size: 35px;
    text-align: center;
    color: $primary;
    position: relative;
    padding-bottom: 12px;
    margin-bottom: 30px;

    &::after {
      content: "";
      display: block;
      width: 98px;
      height: 6px;
      background: url("../images/menu-underline.png") center/contain no-repeat;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &--white {
      color: $white;

      &::after {
        background-image: url('../images/menu-underline-white.png');
      }
    }
  }

  @media (max-width: 1199px) {
    padding: 40px 0;

    &__title {
      font-size: 30px;
    }
  }

  @media (max-width: 767px) {
    padding: 30px 0;

    &__title {
      font-size: 24px;
    }
  }
}

.section-custom {
  &__bg-1 {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  &__bg-2 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    transform: rotateZ(180deg);
  }
}

.section-custom-2 {
  &__bg {
    @include img('bg');
  }
}

.section-custom-3 {
  position: relative;
  padding-bottom: 150px;

  &__bg {
    @include img("bg");
    object-position: 50% 100%;
  }

  @media (max-width: 767px) {
    padding-bottom: 40px;
  }
}

.section-custom-4 {
  background-color: $primary;
  overflow: hidden;

  &__grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  &__col {
    &:first-child {
      padding: 30px 20px 30px 0;
    }

    &:nth-child(2) {
      padding: 30px 0 30px 20px;
      position: relative;
      z-index: 1;

      &::before {
        content: '';
        display: block;
        width: 100vw;
        height: 100%;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }
    }
  }

  @media (max-width: 991px) {
    &__grid {
      grid-template-columns: 100%;
    }

    &__col {
      &:first-child {
        padding: 20px 0;
      }

      &:nth-child(2) {
        padding: 20px 0;

        &::before {
          content: '';
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

.aside {
  &__title {
    font-size: 20px;
    border-bottom: 2px solid #333;
    padding-bottom: 4px;
    margin-bottom: 20px;
    text-align: center;
  }
}
