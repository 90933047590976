.breadcrumb {
  padding: 10px 0;

  &-item {

    &,
    &+&::before,
    &.active,
    &.active::before,
    a,
    a:hover {
      color: $gray-800;
      text-decoration: none;
    }

    a:hover {
      color: $primary;
    }
  }

  .fa {
    display: inline-flex;
    width: 32px;
    height: 28px;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    color: $white;
    text-decoration: none;
  }
}

.breadcrumb-wrapper {
  background-color: rgba($secondary, 0.1);
  border-top: 1px dashed $primary;
  border-bottom: 1px dashed $primary;
  margin: 30px 0 0;

  @media (max-width: 1199px) {
    margin-top: 0;
  }
}
