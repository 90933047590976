.m-last-0:last-child {
  margin: 0 !important;
}

.p-last-0:last-child {
  padding: 0 !important;
}

.border-last-0:last-child {
  border: 0 !important;
}

.font-koz {
  font-family: $koz;
}
