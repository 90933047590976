.news-slider {
  position: relative;
  z-index: 1;
  height: 100%;

  .swiper-container {
    height: 100%;
  }

  .swiper-slide {
    height: auto;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 0;
    z-index: 20;
    width: 100%;
    max-width: 520px;

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 1px solid #333;
      background: 0;
      opacity: 1;
      margin: 0 4px;

      &:focus {
        outline: 0;
      }

      &-active {
        background-color: #333;
      }
    }
  }

  &__item {
    position: relative;
    z-index: 1;
    height: 100%;
  }

  &__frame {
    @include frame("rect", 60%);
    height: 100%;
  }

  &__content {
    width: 100%;
    max-width: 520px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(#fff, 0.7);
    padding: 40px 20px 50px;
  }

  &__title {
    font-size: 24px;
    text-align: center;

    a,
    a:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  @media (max-width: 767px) {
    &__pagination {
      bottom: 5px;
    }

    &__content {
      padding: 16px 10px 24px;
    }

    &__title {
      font-size: 16px;
    }
  }

  @media (max-width: 575px) {
    &__frame {
      @include frame("rect", 75%);
    }

    &__title {
      font-size: 14px;
    }
  }
}

.banner-slider {
  position: relative;
  z-index: 1;

  &__frame {
    display: block;
  }

  &__prev,
  &__next {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    background-color: rgba(#000, 0.4);
    font-size: 18px;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 3%;
    transform: translateY(-50%);
    cursor: pointer;
    @include shadow("");
    transition: 0.3s;

    &:hover {
      background-color: rgba(#000, 0.8);
    }

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: 3%;
  }

  @media (min-width: 1200px) {
    &__prev {
      left: 0;
      opacity: 0;
    }

    &__next {
      right: 0;
      opacity: 0;
    }

    &:hover &__prev {
      left: 3%;
      opacity: 1;
    }

    &:hover &__next {
      right: 3%;
      opacity: 1;
    }
  }
}
