.footer {
  background-color: $secondary;
  padding: 10px 0;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__text {
    color: $primary;
    font-size: 20px;
  }

  @media (max-width: 991px) {
    &__text {
      font-size: 16px;
    }
  }

  @media (max-width: 575px) {
    &__text {
      font-size: 14px;
    }
  }
}
