.header {
  background: url('../images/header-bg.jpg') center/cover no-repeat;
  position: relative;
  z-index: 100;

  &__inner {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__elements {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__btn {
    display: inline-flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    position: relative;
    color: $gray-800;
    font-size: 20px;

    &+& {
      margin-left: 4px;
    }

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    span {
      display: inline-flex;
      width: 15px;
      height: 15px;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      border-radius: 50%;
      font-size: 11px;
      font-weight: 700;
      text-align: center;
      color: $white;
      position: absolute;
      top: 0;
      right: 0;
    }

    img {
      max-height: 16px;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  @media (min-width: 1200px) {
    &__btn {
      display: none;
    }
  }
}

.logo {
  display: inline-flex;
  margin: 20px 0;

  img {
    height: 30px;
    object-fit: contain;
    object-position: 50% 50%;
  }

  @media (min-width: 1200px) {
    margin: 40px 0;

    img {
      height: 45px;
    }
  }
}

.search {
  padding: 10px 20px 20px;

  .input-group {
    border: 1px dashed $primary;
    border-radius: 23px;
  }

  .form-control {
    border: 0;
    background: 0;
    height: 43px;
    color: $primary;
    padding-left: 20px;

    &::placeholder {
      color: $primary;
    }
  }

  .input-group-text {
    border: 0;
    background: 0;
    color: $primary;
    padding-right: 20px;
  }

  @media (min-width: 1200px) {
    width: 300px;
    padding: 0;
    border: 0;
    position: absolute;
    top: -85px;
    right: 0;

    .form-control {
      color: #333;

      &::placeholder {
        color: #333;
      }
    }

    .input-group-text {
      color: #333;
    }
  }
}

.navigation {
  background-color: $secondary;

  &__inner {
    position: relative;
    z-index: 10;
  }
}

.lang {
  padding: 0 20px 20px;

  &__toggle {
    display: none;
  }

  &__dropdown {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 4px 0;
    color: $primary;

    &:hover {
      color: $primary;
      text-decoration: none;
      background-color: rgba(#fff, 0.1);
    }

    img {
      height: 20px;
      margin-right: 12px;
    }
  }

  @media (min-width: 1200px) {
    padding: 0;
    position: absolute;
    top: -120px;
    right: 0;

    &__toggle {
      padding: 7px 0;
      display: inline-block;
      color: $primary;

      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }

    &__dropdown {
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      width: 100%;
      min-width: 160px;
      background-color: $secondary;
      @include shadow('xs');
      transform-origin: 80% 0;

      &::before {
        content: '';
        border: 5px solid transparent;
        border-bottom-color: $secondary;
        position: absolute;
        bottom: 100%;
        right: 40px;
      }
    }

    &__item {
      padding: 8px 16px;

      &:not(:last-child) {
        border-bottom: 1px dashed rgba(#fff, 0.5);
      }
    }

    &:hover &__dropdown {
      display: block;
      animation: menu-float-show 0.3s;
    }
  }
}
