.respond {
  &__title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  &__btn {
    display: inline-block;
    font-size: 20px;
    line-height: 40px;
    padding: 0 25px;
    border: 0;
    background: $primary;
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
      background-color: darken($primary, 5%);
    }
  }

  .form-control {
    border: 0;
    border-bottom: 1px solid $border-color;
    border-radius: 0;
    font-size: 18px;
  }
}

.h-contact {
  display: grid;
  grid-template-columns: 1fr 1fr;

  &__item {
    &:first-child {
      padding-right: 20px;
      border-right: 1px solid $white;
    }

    &:nth-child(2) {
      padding-left: 20px;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    color: $white;
    margin-bottom: 14px;
  }

  &__info {
    color: $white;
    position: relative;
    padding-left: 30px;

    & > .fa {
      position: absolute;
      top: 2px;
      left: 0;
    }

    & + & {
      margin-top: 16px;
    }

    a,
    a:hover {
      color: $white;
    }
  }

  &--single {
    display: block;
  }

  &--single &__item {
    padding: 0;
    border: 0;
  }

  @media (max-width: 575px) {
    &__item {
      &:first-child {
        padding-right: 16px;
      }

      &:nth-child(2) {
        padding-left: 16px;
      }
    }

    &__info {
      padding-left: 24px;
    }
  }
}

.feature {
  display: block;
  color: #333;
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(-50%, -50%);

  &:hover {
    color: #333;
    text-decoration: none;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    background-color: $secondary;
    text-align: center;
    padding: 24px;
    transition: 0.3s;
  }

  &:hover &__wrapper {
    @include shadow("md");
    transform: translateY(-15px);
    background-color: lighten($secondary, 5%);
  }

  &__number {
    font-size: 30px;
    font-weight: 700;
    color: $primary;
  }

  &__text-1 {
    font-size: 12px;
  }

  &__text-2 {
    font-weight: 700;
    font-size: 16px;
  }

  @media (max-width: 1399px) {
    &__wrapper {
      width: 200px;
      height: 200px;
      padding: 20px;
    }

    &__number {
      font-size: 26px;
    }

    &__text-2 {
      font-size: 14px;
    }
  }

  @media (max-width: 1299px) {
    &__wrapper {
      width: 180px;
      height: 180px;
      padding: 16px;
    }
  }

  @media (max-width: 991px) {
    position: static;
    transform: unset;

    &__wrapper {
      display: block;
      width: 100%;
      height: auto;
      padding: 20px;
      border-radius: 4px;
    }
  }
}

.features {
  position: relative;
  z-index: 1;
  max-width: 90vh;
  margin: 0 auto;

  &::before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }

  &__frame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__hold {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__item {
    position: absolute;
    bottom: 50%;
    left: 50%;
    width: 0;
    height: calc(50% - 120px);
    transform-origin: bottom;
  }

  &__item:nth-child(3) {
    transform: rotate(72deg);

    .features__hold {
      transform: rotate(-72deg);
    }
  }

  &__item:nth-child(4) {
    transform: rotate(144deg);

    .features__hold {
      transform: rotate(-144deg);
    }
  }

  &__item:nth-child(5) {
    transform: rotate(-144deg);

    .features__hold {
      transform: rotate(144deg);
    }
  }

  &__item:nth-child(6) {
    transform: rotate(-72deg);

    .features__hold {
      transform: rotate(72deg);
    }
  }

  @media (max-width: 1399px) {
    max-width: 110vh;

    &__item {
      height: calc(50% - 100px);
    }
  }

  @media (max-width: 1299px) {
    max-width: 115vh;

    &__item {
      height: calc(50% - 90px);
    }
  }

  @media (max-width: 991px) {
    &::before {
      display: none;
    }

    &__frame {
      display: flex;
      justify-content: center;
      align-items: center;
      position: static;
      transform: unset;
      margin: 0 auto 20px;
    }

    &__item {
      width: 100% !important;
      position: static !important;
      transform: unset !important;

      & + & {
        margin-top: 4px;
      }
    }

    &__hold {
      width: 100% !important;
      height: auto !important;
      transform: unset !important;
      position: static !important;
    }
  }
}

.about {
  position: relative;
  z-index: 1;
  padding-top: 30px;
  padding-bottom: 230px;

  &__bg {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    min-height: 80px;
    object-fit: cover;
    object-position: 50% 50%;
  }

  &__content {
    font-weight: 500;

    h1,
    h2 {
      border-bottom: 3px solid $secondary;
      margin-bottom: 16px;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 24px;
      padding-bottom: 6px;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 100px;
        border-bottom: 3px solid $primary;
        position: absolute;
        bottom: -3px;
        left: 0;
        z-index: 1;
      }

      &:not(:first-child) {
        margin-top: 26px;
      }
    }

    h1 {
      font-size: 30px;
    }
  }

  @media (max-width: 991px) {
    padding-bottom: 100px;
  }
}

.f-contact {
  position: relative;
  // padding-bottom: 340px;

  &__map {
    display: none;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    position: relative;
    z-index: 2;
    // @include shadow('md');
  }

  &__col {
    padding: 30px;

    &:first-child {
      background-color: $primary;
    }

    &:nth-child(2) {
      background-color: $white;
    }
  }

  @media (max-width: 1199px) {
    &__grid {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 991px) {
    &__grid {
      grid-template-columns: 100%;
    }
  }

  @media (max-width: 767px) {
    padding: 40px 0;

    &__grid {
      grid-template-columns: 100%;
    }
  }
}

.ct-form {
  padding: 40px 0 80px;
  min-height: 60vh;
  position: relative;
  z-index: 1;

  &__bg {
    @include img("bg");
  }

  &__title {
    font-size: 18px;
    text-align: center;
    margin-bottom: 16px;
    color: #777;
  }

  &__btn {
    font-size: 30px;
    line-height: 55px;
    padding: 0 50px;
    border-radius: 4px;
    color: $white;
    background: $primary;
    border: 0;

    &:hover {
      background-color: darken($primary, 5%);
    }
  }

  &__form {
    border: 1px solid $border-color;
    border-radius: 4px;
    padding: 30px 40px;
    background-color: $white;
    box-shadow: 0 1px 2px rgba(#000, 0.15);
  }

  &__select {
    margin: 0 auto;
    max-width: 600px;

    .custom-select {
      padding-left: 30px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 20px;
      cursor: pointer;
      font-size: 16px;

      &:focus {
        box-shadow: unset;
      }
    }
  }

  label {
    display: block;
    font-size: 18px;
    font-weight: 700;
    text-align: center;

    &.radio-styled,
    &.checkbox-styled {
      font-size: 16px;
      line-height: 21px;
    }
  }

  .form-control {
    padding-left: 18px;
    padding-right: 18px;
    font-size: 18px;
  }

  input.form-control {
    height: 40px;
  }

  .custom-select {
    height: 40px;
  }

  &__options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    width: 700px;
    max-width: 100%;
    margin: 0 auto;

    .radio-styled {
      padding: 0;

      &__text {
        display: flex;
        align-items: center;
        height: 100%;
        border: 1px solid $border-color;
        padding: 20px 10px 20px 50px;
        color: $secondary;
        border-radius: 4px;
        transition: 0.3s;
        background-color: $white;
      }

      &__icon {
        top: 50%;
        left: 25px;
        transform: translate(-50%, -50%);
        background: 0;
        border: 1px solid $secondary;
        transition: 0.3s;
      }

      &:hover .radio-styled__text {
        background-color: $secondary;
        color: $white;
      }

      &:hover .radio-styled__icon {
        border-color: $white;
        background-color: $white;
      }

      &__input:checked ~ .radio-styled__text {
        background-color: $secondary;
        color: $primary;
      }

      &__input:checked ~ .radio-styled__icon {
        border-color: $white;
        background-color: $white;

        &::before {
          background-color: $secondary;
        }
      }
    }
  }

  @media (max-width: 767px) {
    &__form {
      padding: 20px;
    }

    &__options {
      grid-template-columns: 1fr;
    }

    &__btn {
      font-size: 20px;
      line-height: 40px;
      padding: 0 30px;
    }

    .form-group {
      label:first-child {
        font-size: 14px;
      }

      .form-control {
        padding-left: 12px;
        padding-right: 12px;
        font-size: 14px;
      }

      input.form-control {
        height: 40px;
      }

      .custom-select {
        height: 40px;
      }
    }
  }
}

.ct-info {
  padding: 40px 0 200px;
  position: relative;
  z-index: 1;

  &__bg {
    @include img("bg");

    object-position: 50% 100%;
  }

  &__title {
    font-size: 30px;
    margin-bottom: 10px;
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #999;
    margin-bottom: 16px;
  }

  &__content {
    margin-bottom: 30px;
  }

  &__map {
    height: 370px;
  }

  @media (max-width: 767px) {
    padding-bottom: 40px;

    &__title {
      font-size: 24px;
    }

    &__subtitle {
      font-size: 16px;
    }
  }
}

.ct-tabs {
  justify-content: center;
  margin-top: 8px;

  .nav-item {
    flex: 1;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .nav-link {
    text-align: center;
    font-size: 18px;
    color: #333;
    padding: 16px;
    border: 1px solid $border-color;
    border-radius: 4px;

    &.active {
      color: $primary;
      background-color: $secondary;
      // box-shadow: inset 0 -3px 0 $primary;
    }
  }

  @media (max-width: 991px) {
    .nav-link {
      padding: 16px;
      font-size: 16px;
      height: 100%;
    }
  }

  @media (max-width: 767px) {
    display: block;
    white-space: nowrap;
    overflow: auto;

    .nav-item {
      display: inline-block;
      min-width: 31%;
    }

    .nav-link {
      padding: 8px 16px;
      text-align: center;
    }
  }
}

.content-wrapper {
  padding-top: 1px;
  background: url("../images/home-bg-2.jpg") repeat-y;
  background-size: 100% auto;
}

.service-btn {
  display: inline-block;
  font-size: 14px;
  line-height: 60px;
  border-radius: 30px;
  overflow: hidden;
  padding: 0 30px 0 90px;
  background-color: $primary;
  color: #fff;
  position: relative;

  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: darken($primary, 5%);
  }

  img {
    position: absolute;
    bottom: 0;
    left: 24px;
  }
}

.menu-aside {
  .menu-item {
    background-color: $secondary;
  }

  & > .menu-item > .menu-link {
    text-transform: uppercase;
  }
}

.h-contact-logo {
  display: inline-block;
  padding: 8px;
  margin-bottom: 20px;

  img {
    max-height: 45px;
  }
}
