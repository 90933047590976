.post {
  &-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #999;
    margin-bottom: 20px;
  }

  &-sapo {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: justify;
  }

  &-content {
    font-weight: 500;
    text-align: justify;
  }
}

.relate-post {
  &__title {
    font-size: 20px;
    padding-bottom: 4px;
    margin-bottom: 16px;

    span {
      display: inline-block;
      padding-bottom: 6px;
      border-bottom: 2px solid $primary;
    }
  }

  &__list {
    margin: 0;
    padding-left: 17px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__link {
    display: inline-block;
    position: relative;
    color: $gray-800;
  }
}
