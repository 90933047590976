.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  color: $primary;

  &-item {
    margin: 4px 0 8px;
    position: relative;
  }

  &-link {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 40px;
    padding: 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  &-toggle {
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    span {
      display: inline-flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      transition: 0.3s;

      &:hover {
        background-color: rgba(#000, 0.1);
      }
    }

    &.active span {
      transform: rotate(90deg);
    }
  }

  &-sub {
    display: none;
  }

  &-sub &-link {
    padding-left: 40px;
  }

  &-sub-2 &-link {
    padding-left: 60px;
  }

  &-sub-3 &-link {
    padding-left: 80px;
  }

  &-item:hover>&-link {
    background-color: rgba(#fff, 0.08);
  }

  &-root>&-item>&-link {
    font-size: 16px;
  }

  @media (min-width: #{$navbar-breakpoint}px) {

    // menu root
    &-root {
      display: flex;
      justify-content: space-between;
    }

    &-root>&-item {
      margin: 0;
    }

    &-root>&-item>&-link {
      padding: 0 !important;
      font-size: 18px;
      line-height: 50px;
    }

    &-root>&-item:hover>&-link {
      background: 0;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 98px;
        height: 6px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background: url('../images/menu-underline.png') center/contain no-repeat;
      }
    }

    &-root>&-item>&-toggle {
      display: none;
    }

    &-root &-sub {
      display: block;
      min-width: 200px;
      // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      // background-color: $dark;
      background: 0;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 100%;
      transform-origin: 0 0;
      padding: 0;
      transform: translateX(100vw);

      &-right {
        left: auto;
        right: 100%;
        transform-origin: 100% 0;
      }
    }

    &-root &-sub &-item {
      margin: 0;
      transform-origin: 0 0;
      transition: 0s;
      transform: rotateZ(45deg) translateY(20px);
      opacity: 0;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      background-color: $secondary;
    }

    &-root &-sub &-link {
      padding-left: 20px;
    }

    &-root &-item-group>&-link {
      padding-right: 40px;
    }

    &-root &-item-group:hover>&-sub {
      display: block;
      transform: translateX(0);
      // animation: menu-float-show 0.2s ease-out;
    }

    &-root &-item-group:hover>&-sub>&-item {
      transform: rotateZ(0) translateY(0);
      opacity: 1;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          transition: 0.35s #{0.1 * ($i - 1)}s ease-out;
        }
      }
    }

    &-root>&-item-group>&-sub {
      transform-origin: 0 0;
      top: 100%;
      left: 0;

      &::before {
        width: 100%;
        height: 8px;
        right: auto;
        left: 0;
        top: auto;
        bottom: 100%;
      }
    }

    &-root>&-item-group:last-child>&-sub {
      transform-origin: 0 100%;
      left: auto;
      right: 0;
    }

    &-root>&-item-group:last-child &-item {
      transform-origin: 100% 0;
    }

    &-root>&-item-group &-sub &-toggle {
      width: 20px;
      right: auto;
      left: 0;

      span {
        transform: rotate(180deg);
      }
    }

    &-root>&-item-group &-sub &-sub {
      left: auto;
      right: 100%;
    }
  }
}
